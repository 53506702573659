import { createGlobalStyle } from "styled-components";

import gothamLightSrc from "../fonts/GothamPro-Light.woff";
import gothamBookSrc from "../fonts/GothamPro.woff";
import gothamMediumSrc from "../fonts/GothamPro-Medium.woff";
import gothamBoldSrc from "../fonts/GothamPro-Bold.woff";
import gothamBlackSrc from "../fonts/GothamPro-Black.woff";
import ywftUltramagneticSrc from "../fonts/YWFTUltramagnetic.woff";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 300;
    src: url(${gothamLightSrc}) format('woff');
  }

  @font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 400;
    src: url(${gothamBookSrc}) format('woff');
  }

  @font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 500;
    src: url(${gothamMediumSrc}) format('woff');
  }

  @font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 700;
    src: url(${gothamBoldSrc}) format('woff');
  }

  @font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 900;
    src: url(${gothamBlackSrc}) format('woff');
  }

  @font-face {
    font-family: 'YWFT Ultramagnetic';
    font-style: normal;
    font-weight: 400;
    src: url(${ywftUltramagneticSrc}) format('woff');
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    min-height: 100vh;
  }

  body {
    min-width: 375px;
    margin: 0;
    color: #fff;
    background-color: #171717;
    font-family: 'GothamPro';
  }

  #___gatsby {
    min-height: 100vh;
  }

  #gatsby-focus-wrapper {
    min-height: 100vh;
  }

  button {
    color: inherit;
    background-color: transparent;
    background-image: none;
    font-family: inherit;
    letter-spacing: inherit;
    border: 0 none;
    outline: none;
    cursor: pointer;
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .grecaptcha-badge {
    z-index: 9999;
  }
`;

export default GlobalStyle;
