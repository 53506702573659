import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 24px;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const CounterPrefix = styled.span`
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const CounterDecor = styled.span`
  display: block;
  position: absolute;
  left: 50%;
  width: 100%;
  height: 1px;
  background-color: #dca944;
  transform: translateX(-50%);

  @media (max-width: 1023px) {
    top: 11px;
  }

  @media (min-width: 1024px) {
    top: 14px;
  }
`;

export const NumberItem = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & + & {
    margin-left: 20px;
  }
`;

export const Number = styled.span`
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: 1023px) {
    width: 50px;
    font-size: 34px;
    line-height: 28px;
  }

  @media (min-width: 1024px) {
    width: 68px;
    font-size: 46px;
    line-height: 37px;
  }
`;

export const Desc = styled.span`
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 12px;
    font-size: 12px;
    letter-spacing: 1.8px;
    line-height: 12px;
  }

  @media (min-width: 1024px) {
    margin-top: 8px;
    font-size: 9px;
    letter-spacing: 1.35px;
    line-height: 28px;
  }
`;

export const NumberNew = styled.span`
  display: inline-block;

  @media (max-width: 1023px) {
    width: 18px;
  }

  @media (min-width: 1024px) {
    width: 23px;
  }
`;
