import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import {
  Root,
  CounterContainer,
  // CounterPrefix,
  CounterDecor,
  NumberItem,
  Number,
  Desc,
  NumberNew,
} from "./Countdown.style";

function getTimeLeft(expires) {
  const difference = +expires - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

const Countdown = ({
  expires,
  timezone,
  variant = "primary",
  showDecor = true,
  shortNames = false,
  ...props
}) => {
  const expiresDate = DateTime.fromObject(expires, {
    zone: timezone,
  }).toJSDate();
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(expiresDate));

  useEffect(() => {
    const id = setTimeout(() => setTimeLeft(getTimeLeft(expiresDate)), 1000);
    return () => clearTimeout(id);
  });

  const timerComponents = Object.keys(timeLeft).map(interval => {
    return (
      <NumberItem key={interval}>
        <Number>
          {timeLeft[interval] < 10
            ? `0${timeLeft[interval]}`
            : timeLeft[interval]}
        </Number>
        <Desc>{interval}</Desc>
      </NumberItem>
    );
  });

  return (
    <Root {...props}>
      <CounterContainer>
        {variant === "primary" ? (
          <>
            <CounterDecor />
            {timerComponents.length ? (
              timerComponents
            ) : (
              <NumberItem style={{ marginBottom: 8 }}>
                <Number style={{ width: "100%" }}>Time’s up!</Number>
              </NumberItem>
            )}
          </>
        ) : timerComponents.length ? (
          <>
            {showDecor && (
              <svg
                width={18}
                viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 13.848 17.858 L 8.573 13.421 L 2.74 17.017 L 5.322 10.637 L 0.093 6.2 L 6.953 6.712 L 9.575 0.333 L 11.196 7.043 L 18.056 7.554 L 12.23 11.15 L 13.848 17.858 Z"
                  fill="#c49f3f"
                ></path>
              </svg>
            )}
            <span>
              {/* <CounterPrefix>Starts In&nbsp;</CounterPrefix> */}
              {timeLeft.days}
              {shortNames ? "D" : "DAYS"}&nbsp;
              {timeLeft.hours}
              {shortNames ? "H" : "HRS"}&nbsp;{timeLeft.minutes}
              {shortNames ? "M" : "MINS"}&nbsp;
              {timeLeft.seconds < 10 ? (
                <NumberNew>0{timeLeft.seconds}</NumberNew>
              ) : (
                <NumberNew>{timeLeft.seconds}</NumberNew>
              )}
              {shortNames ? "S" : "SECS"}
            </span>
            {showDecor && (
              <svg
                width={18}
                viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 13.848 17.858 L 8.573 13.421 L 2.74 17.017 L 5.322 10.637 L 0.093 6.2 L 6.953 6.712 L 9.575 0.333 L 11.196 7.043 L 18.056 7.554 L 12.23 11.15 L 13.848 17.858 Z"
                  fill="#c49f3f"
                ></path>
              </svg>
            )}
          </>
        ) : variant === "primary" ? (
          <Number style={{ width: "100%" }}>Time’s up!</Number>
        ) : (
          <span>Time’s up!</span>
        )}
      </CounterContainer>
    </Root>
  );
};

Countdown.propTypes = {
  expires: PropTypes.objectOf(PropTypes.number).isRequired,
  timezone: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

export default Countdown;
